import type { JSONContent } from '@tiptap/core';
import { TokenType } from './design-tokens-types';

export const BlockSizes = ['large', 'medium', 'small'] as const;
type BlockSize = (typeof BlockSizes)[number];

export const BlockSpacings = ['large', 'medium', 'small'] as const;
type BlockSpacing = (typeof BlockSpacings)[number];

export type BlockHeader = {
  showHeader?: boolean;
  title?: string;
  description?: string;
};

/**
 * Data for blocks made using the `createEmbedBlock` function
 */
type IframeBlockData = {
  url?: string;
  clickToInteract: boolean;
  height?: number | string;
};

type BlockConfigBase<
  BlockType extends string,
  Data extends Record<string, unknown> = never,
> = {
  // Unique across all blocks, basically a UUID upon creation
  // e.g. `ut79D2X_H`
  id: string;
  // Match this to a Slices `id` to use it
  blockType: BlockType;
  data: Data;
  header?: BlockHeader;
  size?: BlockSize;
  /** Controls the amount of spacing AFTER a block */
  spacing?: BlockSpacing;
};

type BlockConfigUnion =
  | BlockConfigBase<
      'component-spec-block',
      {
        patternId?: string;
        templateId?: string;
        collapsible?: boolean;
        expanded?: boolean;
      }
    >
  | BlockConfigBase<
      'pattern-template-block',
      {
        patternId?: string;
        templateId?: string;
        demoId?: string;
        /** @deprecated */
        demoSize?: string;
        showSchemaForm?: boolean;
        showCodeSnippet?: boolean;
        hideControls?: boolean;
        allowResizing?: boolean;
      }
    >
  | BlockConfigBase<
      'design-src-tiles',
      {
        columns?: '4' | '3' | '2' | '1';
        imageStyles?: {
          align?: 'left' | 'right' | 'center';
          background?: 'dark' | 'light';
          corners?: 'square' | 'rounded';
          fit?: 'contain' | 'fill';
          seamless?: boolean;
          size?: 'auto' | 'small' | 'medium' | 'large' | 'xsmall' | 'xlarge';
        };
        items: {
          description?: JSONContent;
          fileId: string;
          nodeId: string;
          title?: string;
        }[];
        layout?: 'grid' | 'list';
        hideComponentProps?: boolean;
      }
    >
  | BlockConfigBase<
      'design-src-component',
      {
        fileId: string;
        componentId: string;
        contents: Array<'variants' | 'spec' | 'usage'>;
        styles?: {
          columns?: '4' | '3' | '2' | '1';
          image?: {
            align?: 'left' | 'right' | 'center';
            background?: 'dark' | 'light';
            corners?: 'square' | 'rounded';
            size?: 'auto' | 'small' | 'medium' | 'large' | 'xsmall' | 'xlarge';
          };
          layout?: 'grid' | 'list';
        };
        hideComponentProps?: boolean;
        hideComponentTitles?: boolean;
      }
    >
  | BlockConfigBase<
      'text-editor',
      {
        autoFocus?: boolean;
        content: JSONContent;
      }
    >
  | BlockConfigBase<
      'files-list',
      {
        files: string[];
      }
    >
  | BlockConfigBase<
      'callout',
      {
        type: 'error' | 'info' | 'success' | 'warning';
        content: JSONContent;
      }
    >
  | BlockConfigBase<
      'code-snippet',
      {
        content: JSONContent;
      }
    >
  | BlockConfigBase<'divider-block', Record<string, never>>
  | BlockConfigBase<
      'guidelines',
      {
        guidelines: {
          id: string;
          image?: {
            src: string;
            title?: string;
            alt?: string;
          };
          imageFit?: 'contain' | 'cover';
          type: 'caution' | 'do' | 'dont';
          content?: JSONContent;
        }[];
        gridStyles?: {
          centered?: boolean;
          columns?: '4' | '3' | '2' | '1';
        };
        tileStyles?: {
          headerBeforeImage?: boolean;
          imageOutlined?: boolean;
          imageRounded?: boolean;
          imageSize?:
            | 'none'
            | 'small'
            | 'medium'
            | 'large'
            | 'xsmall'
            | 'xlarge';
        };
      }
    >
  | BlockConfigBase<
      'image-block',
      {
        imageSize?: 'xs' | 's' | 'm' | 'l' | 'full';
        images?: {
          caption?: string;
          src: string;
        }[];
      }
    >
  | BlockConfigBase<
      'shortcut-tiles',
      {
        shortcuts: {
          description?: string;
          id: string;
          image?: string;
          link: {
            external?: boolean;
            name?: string;
            path: string;
          };
          title: string;
        }[];
        gridStyles?: {
          columns?: '4' | '3' | '2' | '1';
          centered?: boolean;
          gapSize?: number;
        };
        tileStyles?: {
          imageSize?: 'none' | 'xsmall' | 'small' | 'medium' | 'large';
          outlined?: boolean;
          rounded?: boolean;
          titleSize?: 'xsmall' | 'small' | 'medium' | 'large' | 'xlarge';
        };
      }
    >
  | BlockConfigBase<
      'table',
      {
        content: JSONContent;
      }
    >
  | BlockConfigBase<
      'pattern-variations',
      {
        patternId?: string;
        templateId?: string;
        demoId?: string;
        variationId?: string;
        assetSetId?: string;
        excludePropValue?: string;
        includePropValue?: string;
        size?: 's' | 'm' | 'l' | 'full';
        /** @deprecated */
        hideBlockTitle?: boolean;
      }
    >
  | BlockConfigBase<
      'design-tokens',
      {
        tokenType: TokenType | '';
        demoId: string;
        groupIds: string[];
        exclude?: string;
        include?: string;
      }
    >
  | BlockConfigBase<
      'design-token-collection',
      {
        collectionId: string;
      }
    >
  | BlockConfigBase<
      'pattern-list-block',
      {
        itemSize?: 'small' | 'medium' | 'large' | 'full';
        showDescriptions?: boolean;
        excludePatterns?: string[];
      }
    >
  | BlockConfigBase<
      'pattern-status-table-block',
      {
        excludeHiddenNavItems?: boolean;
        excludePatterns?: string[];
      }
    >
  | BlockConfigBase<
      'video',
      {
        video: {
          format: string;
          name: string;
          size: string;
          url: string;
        };
      }
    >
  /** @deprecated */
  | BlockConfigBase<
      'design-token-block',
      {
        tokens?: {
          type?: TokenType;
          category?: string;
          tags?: string[];
          name?: string;
          exclude?: string;
        };
        demo?: {
          id?: string;
        };
      }
    >
  | BlockConfigBase<'generic-iframe', IframeBlockData>
  | BlockConfigBase<'abstract-block', IframeBlockData>
  | BlockConfigBase<'adobe-xd-block', IframeBlockData>
  | BlockConfigBase<'airtable-block', IframeBlockData>
  | BlockConfigBase<'figma-block', IframeBlockData>
  | BlockConfigBase<'framer-cloud-block', IframeBlockData>
  | BlockConfigBase<'lucidchart-block', IframeBlockData>
  | BlockConfigBase<'vimeo-block', IframeBlockData>
  | BlockConfigBase<'youtube-block', IframeBlockData>
  | BlockConfigBase<'whimsical-block', IframeBlockData>
  | BlockConfigBase<
      'markdown-block',
      {
        md?: string | string[];
        /** Automatically switches to edit mode + autofocuses on the editor */
        autoEdit?: boolean;
        columns?: number;
      }
    >;

/**
 * This is the config for a block - which is what is stored in `block.ID.json` and available at `data.db.blocks.byId[blockId]`
 *
 * @example
 * // Use the first generic to filter down to a specific block type
 * type PatternListBlockConfig = BlockConfig<'pattern-list-block'>;
 * // Access the field `data` by doing this:
 * type Data= BlockConfig<'pattern-list-block'>['data'];
 */
export type BlockConfig<
  BlockType extends BlockConfigUnion['blockType'] = BlockConfigUnion['blockType'],
> = Extract<BlockConfigUnion, { blockType: BlockType }>;

/** Block Collections can be in several locations */
export type BlockCollectionLocation =
  | {
      type: 'page';
      pageId: string;
    }
  /** Tab on Pattern Page */
  | {
      type: 'patternSubPage';
      patternId: string;
      subPageId: string;
    }
  /** At bottom of Pattern Template page (under Spec table) - least used location */
  | {
      type: 'patternTemplate';
      patternId: string;
      templateId: string;
    };
