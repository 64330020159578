import type { KnapsackPatternTemplate, TemplateDemo } from './app-client-data';

export const rendererIds = {
  react: 'react',
  vue: 'vue',
  'web-components': 'web-components',
  twig: 'twig',
  hbs: 'hbs',
  angular: 'angular',
  html: 'html',
} as const;

/**
 * The ID of a renderer, e.g. 'react', 'vue', 'angular', etc.
 * On Pattern Templates, this will match up to `templateLanguageId` to identify which renderer to use.
 * @see {KnapsackPatternTemplate['templateLanguageId']}
 */
export type RendererId = keyof typeof rendererIds;
/**
 * Results of rendering a pattern
 */
export type KsRenderResults = {
  ok: boolean;
  html: string;
  usage?: string;
  templateLanguage?: string;
  message?: string;
};

export type KsRenderApiResults = KsRenderResults & {
  wrappedHtml: string;
};
export type TemplateSuggestion = {
  path: string;
  alias?: string;
};

export type TemplateSuggestionsGetFn = (opt: {
  rendererId: RendererId;
  newPath?: string;
  state: ContentStateForRendering;
}) => Promise<{
  suggestions: TemplateSuggestion[];
}>;

/**
 * Aliases for package paths
 * Example:
 * ```js
 * {
 *   'my-pkg': '../path/to/my-pkg',
 * }
 * ```
 */
export type PkgPathAliases = Record<string, string>;

export type ContentStateForRendering = {
  /**
   * Patterns by ID
   */
  patterns: {
    [patternId: string]: {
      id: string;
      templates: Pick<
        KnapsackPatternTemplate,
        'alias' | 'path' | 'templateLanguageId' | 'spec' | 'id' | 'title'
      >[];
      templateDemos: Array<
        TemplateDemo & {
          templateId: string;
          templateLanguageId: RendererId;
        }
      >;
    };
  };
};
