import { KsAppClientData, PluginsApi, KsRenderResults } from '@knapsack/types';
import type {
  GenericResponse,
  KsAppClientDataAndMeta,
  KsAppClientDataNoMeta,
  KsFileSaverParams,
} from '@knapsack/types';
import { fetcher } from '@knapsack/utils';
import * as getTemplateSuggestions from './get-template-suggestions';

export interface KnapsackDataStoreSaveBody {
  state: KsAppClientData;
}

export type PatternRenderDataWithDemoId = {
  patternId: string;
  templateId: string;
  assetSetId: string;
  demoId: string;
};

export type PatternRenderData = {
  patternId: string;
  templateId: string;
  assetSetId?: string;
  wrapHtml?: boolean;
  isInIframe?: boolean;
  cacheBuster?: string;
  /**
   * Data id for Demo from `saveData()`
   * @see {@link Demo}
   */
  dataId: string;
  /**
   * ID for @see {ContentStateForRendering}
   */
  stateId: string;
};

export const ENDPOINTS = {
  health: '/api/v1/',
  dataStore: '/api/v1/data-store',
  dataStorePrep: '/api/v1/data-store-prep',
  files: '/api/v1/files',
  data: '/api/v1/data',
  render: '/api/v1/render',
  upload: '/api/v1/upload',
  plugins: '/api/v1/plugins',
  getTemplateSuggestions: getTemplateSuggestions.path,
};

export async function checkAppClientUrl(url: string): Promise<GenericResponse> {
  try {
    const theUrl = new URL(ENDPOINTS.health, url); // will throw if url not formatted correctly
    const res = await fetcher<GenericResponse>({
      url: theUrl.toString(),
    });
    return res;
  } catch (err) {
    return {
      ok: false,
      message: err instanceof Error ? err.message : 'Unknown error',
    };
  }
}

export async function getDataStore({
  appClientUrl,
}: {
  appClientUrl: string;
}): Promise<GenericResponse<KsAppClientDataAndMeta>> {
  try {
    const data = await fetcher<KsAppClientDataAndMeta>({
      url: new URL(ENDPOINTS.dataStore, appClientUrl).toString(),
    });

    return {
      ok: true,
      data,
    };
  } catch (err) {
    return {
      ok: false,
      message: err instanceof Error ? err.message : 'Unknown error',
    };
  }
}

// just need a function that can throw an error
export async function getAppClientData({
  appClientUrl,
}: {
  appClientUrl: string;
}): Promise<KsAppClientDataAndMeta> {
  const serverDataRes = await getDataStore({ appClientUrl });
  if (!serverDataRes.ok || !serverDataRes.data) {
    const message = `Could not load data from custom url "${appClientUrl}" ${serverDataRes.message}`;
    throw new Error(message);
  }
  return serverDataRes.data;
}

/** Sole job is to strip `metaState` if it's there */
export function prepAppClientData(
  state: KsAppClientData,
): KsAppClientDataNoMeta {
  const { metaState, ...appClientDataNoMeta } = state;
  return appClientDataNoMeta;
}

export async function prepareDataForFileSave({
  urlBase,
  state,
}: {
  urlBase: string;
  state: KsAppClientDataNoMeta;
}): Promise<GenericResponse<KsFileSaverParams>> {
  try {
    const body: KnapsackDataStoreSaveBody = {
      state: prepAppClientData(state),
    };
    const data = await fetcher<GenericResponse<KsFileSaverParams>>({
      url: new URL(ENDPOINTS.dataStorePrep, urlBase).toString(),
      body,
    });

    return data;
  } catch (err) {
    return {
      ok: false,
      message: err instanceof Error ? err.message : 'Unknown error',
    };
  }
}

export async function submitDataForFileSave({
  urlBase,
  state,
}: {
  urlBase: string;
  state: KsAppClientDataNoMeta;
}): Promise<void> {
  const appClientData = prepAppClientData(state);
  const body: KnapsackDataStoreSaveBody = {
    state: appClientData,
  };
  const data = await fetcher<GenericResponse>({
    url: new URL(ENDPOINTS.dataStore, urlBase).toString(),
    body,
  });

  if (!data.ok) {
    throw new Error(`Could not save data to localhost files: ${data.message}`);
  }
}

export async function getPluginContent({
  pluginId,
  appClientUrl,
}: {
  pluginId: string;
  appClientUrl: string;
}): Promise<PluginsApi.GetContentResponse> {
  const body: PluginsApi.GetContentRequest = {
    pluginId,
    type: PluginsApi.ACTIONS.getContent,
  };
  const data = await fetcher<PluginsApi.GetContentResponse>({
    url: new URL(ENDPOINTS.plugins, appClientUrl).toString(),
    body,
  });
  return data;
}

export async function renderTemplate({
  options,
  appClientUrl,
}: {
  options: PatternRenderData;
  appClientUrl: string;
}): Promise<KsRenderResults> {
  const data = await fetcher<KsRenderResults>({
    url: new URL(ENDPOINTS.render, appClientUrl).toString(),
    body: options,
  });
  return data;
}
