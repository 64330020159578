import { isBrowser } from '@knapsack/utils';
import { isCypress } from './constants';

/**
 * Have any of these keys as a url param and it'll enable that feature until you close the tab even if the url param goes away. Use `yes` and `no` for boolean values
 * @example
 * /about?enableStateInspector=yes
 * /about?skipDbForAppClientData=no
 */
export interface FeatureFlags {
  enableStateInspector: boolean;
  /** Set this to not load AppClientData from DB, but instead make API call to deployed App Client site to get AppClientData */
  skipDbForAppClientData: boolean;
  /** Load a site just like if it had `site.isPrivate` set in DB. Should probably be removed later - using for testing now. */
  forceSiteToPrivate: boolean;
  /** overrides the App API GraphQL endpoint */
  appApiGqlEndpoint: string;
  /** API now applies patches to loaded content */
  loadContentViaApi: boolean;
}

let flags: FeatureFlags = {
  enableStateInspector: false,
  skipDbForAppClientData: true,
  forceSiteToPrivate: false,
  appApiGqlEndpoint: '',
  loadContentViaApi: false,
};

function coerceBoolValue(value: string): boolean {
  if (value === 'yes') return true;
  if (value === 'no') return false;
}

const lsKey = 'knapsack-feature-flags';
const urlFlags: Partial<FeatureFlags> = {};
let localStorageFlags: Partial<FeatureFlags> = {};

try {
  localStorageFlags = JSON.parse(window.sessionStorage.getItem(lsKey) ?? '{}');
} catch (e) {
  //
}

const urlParams = new URLSearchParams(
  typeof window !== 'undefined' && window.location.search,
);
const isFlag = (x: string): x is keyof FeatureFlags => x in flags;

urlParams.forEach((value, key) => {
  if (isFlag(key)) {
    switch (key) {
      case 'appApiGqlEndpoint':
        urlFlags[key] = value;
        break;
      default:
        urlFlags[key] = coerceBoolValue(value);
        break;
    }
  }
});

if (Object.keys(urlFlags).length > 0) {
  localStorageFlags = {
    ...localStorageFlags,
    ...urlFlags,
  };
  window.sessionStorage.setItem(lsKey, JSON.stringify(localStorageFlags));
}

flags = {
  ...flags,
  ...localStorageFlags,
};

if (isCypress()) {
  flags.enableStateInspector = false;
}

export const featureFlags = { ...flags };

export function setDevFeatureFlags(f: Partial<FeatureFlags>): void {
  const url = new URL(window.location.href);
  Object.entries(f).forEach(([key, value]) => {
    let v: string;
    if (typeof value === 'boolean') {
      v = value ? 'yes' : 'no';
    } else {
      v = value;
    }

    url.searchParams.set(key, v);
  });
  window.location.href = url.toString();
}

if (process.env.NODE_ENV !== 'test' && isBrowser) {
  console.debug(`feature flags`, featureFlags);
}
