import type { TemplateSuggestionsGetFn } from '@knapsack/types';
import { fetcher, Except } from '@knapsack/utils';

export const path = '/api/v1/template-suggestions';
export const method = 'GET';
export type ReqParams = Except<
  Parameters<TemplateSuggestionsGetFn>[0],
  'state'
> & {
  /**
   * ID from saving {@link ContentStateForRendering} to `api.knapsack.cloud/render-data
   */
  stateId: string;
};
export type ResBody =
  | {
      type: 'success';
      data: Awaited<ReturnType<TemplateSuggestionsGetFn>>;
    }
  | {
      type: 'error.invalidParams';
      message: string;
    }
  | {
      type: 'error.pathNotFound';
      message: string;
    }
  | {
      type: 'error.couldNotParse';
      message: string;
    };

export async function getTemplateSuggestions({
  appClientUrl,
  query,
}: {
  appClientUrl: string;
  query: ReqParams;
}): Promise<ResBody> {
  return fetcher<ResBody>({
    url: new URL(path, appClientUrl).toString(),
    query,
  });
}
