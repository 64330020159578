import { isBrowser } from '@knapsack/utils';
import { knapsackGlobal } from '@/global';
import { ksHttpHeaders } from '@knapsack/core';
import { extractPossibleInstanceIdFromSite } from '@/core/env-and-content-src/utils';
import { getUserToken } from '@/utils/user-token';

function getSiteIdAndInstanceId() {
  // given `/site/toby/abc123`
  // theWordSite is 'site'
  // siteId is 'toby'
  // instanceId is 'abc123'
  const [, theWordSite, siteId, instanceId] =
    window.location.pathname.split('/');
  if (
    // in the `/site/*` path
    theWordSite === 'site' &&
    // not in the `/site/custom` path - which is local dev and therefore we cannot rely on url for getting info - we'll use Xstate context below instead
    siteId !== 'custom' &&
    // have a siteId
    siteId &&
    // have an instanceId
    instanceId
  ) {
    // ok, then we can use the info in url to reliably get siteId and instanceId
    return { siteId, instanceId };
  }
  // otherwise, we'll use Xstate context
  const { site } = knapsackGlobal.appService.getSnapshot().context;
  return {
    siteId: site?.meta.siteId,
    instanceId: extractPossibleInstanceIdFromSite(site),
  };
}

export async function getHttpHeaders(): Promise<Record<string, string>> {
  if (!isBrowser) {
    throw new Error('getHttpHeaders() can only be used in browser');
  }
  const { siteId, instanceId } = getSiteIdAndInstanceId();
  const headers: Record<string, string> = {
    [ksHttpHeaders.siteId]: siteId,
    [ksHttpHeaders.instanceId]: instanceId,
  };
  try {
    const token: string = await getUserToken();
    if (token) {
      headers.Authorization = `Bearer ${token}`;
    }
  } catch (e) {
    // Muffle error here. Error is logged within getUserToken()
  }
  return headers;
}
